import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import RichText from '~components/RichText'
import Image from '~components/Image'
import Seo from '~components/Seo'
import Section from '~components/Section'
import DataViewer from '~utils/DataViewer'
import SanityLink from '~components/SanityLink'
import { mobile, tablet, HeaderImageDesk, HeaderImageMobile } from '~styles/global'
import RenderModules from '~components/RenderModules'

const Page = ({ data }) => {
	const { page } = data
	return(
		<>
			<Seo 
				title={page?.title}
				metaTitle={page?.seo?.metaTitle}
				description={page?.seo?.metaDescription}
				image={page?.seo?.socialImage}
			/>
			<HeaderImageDesk image={page?.headerImage} cover hideMobile={page?.headerImageMobile}/>
			<HeaderImageMobile image={page?.headerImageMobile} cover/>
			<StyledRenderModules modules={page?.modules} padding={!page?.headerImage}/>
			<DataViewer data={page} name="page"/>
		</>
	)
}

const StyledRenderModules = styled(RenderModules)`
	padding-top: ${ props => props.padding ? '100px' : '0px'};
	${mobile}{
		padding-top: ${ props => props.padding ? '10px' : '0px'};
	}
`
Page.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
  query PageQuery($slug: String) {
    page: sanityPage(slug: {current: {eq: $slug}}) {
      title
			seo{
				...seo
			}
			headerImage {
				...imageWithAlt
			}
			headerImageMobile{
				...imageWithAlt
			}
			modules {
				... on SanityAccordion {
					...accordion
				}
				... on SanityAccreditedBroker {
					_key
					_type
				}
				... on SanityDividingLine {
					_key
					_type
				}
				... on SanityArticleCollection {
					...articleCollection
				}
				... on SanityContactForm {
					...contactForm
				}
				... on SanityHeadingTextModule {
					...headingText
				}
				... on SanityImageModule {
					...imageModule
				}
				... on SanityLogoGrid {
					...logoGrid
				}
				... on SanityTestimonials {
					...testimonials
				}
				... on SanityList {
					...list
				}
				... on SanityTeam {
					...team
				}
			}
    }
  }
`

export default Page